video {
    object-fit: cover;
    width: 100%;
    height: 60%;
    position: absolute;
    z-index: -1;
}

.hero-container {
    /*background: url("/img/luciano.jpg") center center/cover no-repeat;*/
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-child {
    display: block;
    align-items: center;
    flex-direction: row;
}

.hero-child > img {
    display: block;
    margin: 5% auto;
    width: 30%;
}

.hero-child > a > button {
    display: block;
    margin: 0 auto;
    width: 60%;
    margin-bottom: 5%;
}

.hero-child > p {
    margin-top: 5%;
    text-align: center;
    padding-bottom: 12px;
    color: #fff;
    font-size: 32px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
    font-style: italic;
}
.semi {
    display: inline-block;
    vertical-align: bottom;
    width: 140%;
    height: 50vh;
    background-color: #fff;
    border-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: -5%;
    z-index: 1;
}
.bellow {
    margin-top: 17% !important;
}

.eva {
    margin-top: -18%;
}

.chamullo {
    margin-top: -2%;
    padding-top: 7%;
    width: 100%;
    height: 50vh;
    text-align: center;
    color: #6c757d;
    font-style: italic;
}

.fra {
    z-index: 4 !important;
}

.huh {
    margin: auto;
    margin-top: -10%;
    width: 40% !important;
}
.hero-btns {
    margin-top: 32px;
    z-index: 3;
}

.hero-btns .btn {
    margin: 6px;
    background-color: transparent;
    color: #1b1919;
    padding: 8px 20px;
    width: 240px;
    border: 1px solid #1b1919;
    transition: all 0.3s ease-out;
}
.hero-btns .btn:hover {
    transition: all 0.3s ease-out;
    background: #242424;
    color: #fff;
    transition: 250ms;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
    }
    .white-icon-allegro {
        padding-top: 30% !important;
    }
    .datenshi h1 {
        text-align: center;
        font-size: medium;
    }
    .datenshi h5 {
        font-size: small;
    }
    .semi {
        padding-top: 5%;
        margin-top: 5%;
    }

    .chamullo {
        display: block !important;
    }
    .chamullo p {
        margin-top: 20%;
        margin-left: 5%;
        width: 100% !important;
    }
    .chamullo div {
        max-width: 100%;
    }
    .chamullo iframe {
        margin-top: 5%;
        margin-left: 5%;
        width: 100% !important;
        height: 100% !important;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 50px;
        text-align: center;
    }

    .hero-container > p {
        font-size: 30px;
        text-align: center;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}
