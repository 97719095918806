/* Style all font awesome icons */
.big {
    font-size: 420%;
}
.eva {
    padding-top: 3%;
}
.evo {
    padding-bottom: 3%;
}

.fa {
    padding: 10px;
    font-size: 20px;
    width: 40px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
}

/* Add a hover effect if you want */
.fa:hover {
    opacity: 0.7;
}

.fa-linkedin-in {
    background: #007bb5;
    color: white;
}

.team-member {
    margin-bottom: 3rem;
    text-align: center;
}
.team-member img {
    width: 14rem;
    height: 14rem;
    border: 0.5rem solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 960px) {
    .team-member > h4 {
        font-size: 25px;
        text-align: center;
    }

    .team-member > p {
        font-size: 20px;
        text-align: center;
    }
}

@media screen and (max-height: 765px) {
    .team-member img {
        width: 10rem;
        height: 10rem;
    }
    .team-member {
        height: 50%;
        width: 50%;
        margin: auto;
        text-align: center;
    }
    .eva2 {
        padding-top: 1%;
    }
    .evo {
        padding-bottom: 0%;
    }
    .teamhead h2 {
        font-size: 28px;
    }
    .teamhead h5 {
        font-size: 22px;
    }
}
