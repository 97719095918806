* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "PT Sans", sans-serif;
}

.notFound {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notFound img {
    margin: auto;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}
