video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.imagen {
    border: 0.5rem solid rgba(0, 0, 0, 0.1);
}
.EVAwi {
    margin-top: -12%;
}
.eva2 {
    padding-top: 3%;
}
.evo {
    padding-bottom: 3%;
}

.allegro-icon {
    display: block;
    width: 40%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.allegro-text {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.wut {
    display: inline-block;
    vertical-align: bottom;
    width: 140%;
    height: 50vh;
    background-color: #fff;
    border-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 3%;
}
.hero-container {
    /*background: url("/img/luciano.jpg") center center/cover no-repeat;*/
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: #fff;
    font-size: 50px;
    margin-top: 3% !important;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}

.villian-container {
    background: url("/img/cond1.jpg") center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    margin-top: 5%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.villian-container > h2 {
    font-size: 38px !important;
    margin-top: -100px;
    margin-bottom: 24px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 250px;
    padding-right: 250px;
}

.villian-container > p {
    color: #fff;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 250px;
    padding-right: 250px;
    font-size: 20px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}

@media screen and (max-width: 960px) {
    .hero-container {
        height: 40vh !important;
    }

    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }

    .wut {
        height: 30vh;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
        text-align: center;
    }

    .hero-container > p {
        font-size: 30px;
        text-align: center;
    }
    .villian-container > h2 {
        margin-top: 0px;
        text-align: center;
    }

    .villian-container > p {
        font-size: 10px;

        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
}
