.imagen {
    border: 0.5rem solid rgba(0, 0, 0, 0.1);
}

.big {
    font-size: 100%;
}
.vcenter {
    margin: auto;
}

.eva {
    padding-top: 1%;
}
.evo {
    padding-bottom: 3%;
}

.xd {
    padding-left: 15%;
    background-color: #cccccc;
    margin-bottom: 1%;
}

.timeline-left {
    text-align: right;
}

.timeline-body {
    text-align: left;
}

.timeline-header {
    font-size: 250%;
}
@media screen and (max-width: 960px) {
    .text-center {
        font-size: 60%;
    }
    .timeline-header {
        font-size: 100%;
    }
    .timeline-body {
        font-size: 80%;
    }
}
